import React from 'react'
import {isEmpty } from 'lodash'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  FormWrapper,
  FormRedirect,
  FormHeader,
  MessageCard,
  Banner,
} from '@foursquare/cupcake'
import { LoginForm } from './forms/LoginForm'
import { OrgSelectForm } from './forms/OrgSelectForm'
import { BaseLoginProps, TileType } from '..'

const genErrorMessage = (error) => {
  switch (error) {
    case 'failed_recaptcha':
      return 'Recaptcha verification failed.'
    case 'disabled':
      return 'The account provided has been disabled. Please contact Factual if you think this is in error.'
    case 'inactive':
      return 'This account is no longer active.'
    default:
      return 'The email or password you entered is incorrect. Please try again and make sure your caps lock is off.'
  }
}

const ErrorMessage = ({ error }: {error: string | null}) => {
  const errorText = genErrorMessage(error)
  if (error) {
    return (
      <Banner
        className='mb-fsq-3'
        bannerType='Error'
        bodyText={errorText}
        dismissible={false}
      />
    )
  } else {
    return null
  }
}

const DevMessage = ({ dev }: {dev: boolean}) => {
  if (dev) {
    return (
      <MessageCard>
        <p>
          You are on the <strong>dev</strong> environment.<br />
          Your password is <em>temp123</em>.
        </p>
      </MessageCard>
    )
  } else {
    return null
  }
}

export const ReCaptcha = (props) => {
  if (props.error == 'not_found_require_recaptcha' ||
    props.error == 'failed_recaptcha') {
    return (
      <div className="mb-6">
        <ReCAPTCHA
          sitekey={props.recaptchaKey}
        />
      </div>
    )
  } else {
    return null
  }
}

const FormBaseView = (props: LoginTile) => {
  return (
    <div>
      <DevMessage dev={props.dev} />
      <LoginForm {...props} />
    </div>
  )
}

const FormOrgSelectView = (props: LoginTile) => {
  return (
    <OrgSelectForm {...props} />
  )
}

interface LoginTile extends BaseLoginProps {
  changeTileType: (type: TileType) => void
}

export const LoginTile = (props: LoginTile) => {
  return (
    <div>
      <FormRedirect>
        Don&apos;t have an account? <a className="text-brand-primary hover:underline" href="https://foursquare.com/contact-us/">Contact Us</a>
      </FormRedirect>
      <FormWrapper>
        <FormHeader> Log In </FormHeader>
        <div>
          <ErrorMessage error={props.error} />
          {
            isEmpty(props.accounts) ?
              <FormBaseView {...props} /> :
              <FormOrgSelectView {...props} />
          }
        </div>
      </FormWrapper>
    </div>
  )
}
