import React, { useEffect, useState } from 'react';
import {
  Button,
  FormWrapper,
  FormRedirect,
  FormHeader,
  TextField,
  Banner,
} from '@foursquare/cupcake'

import { BaseLoginProps, TileType } from '../';
import { isNil } from 'lodash';
import { validateEmailAddress } from '../../../utilities';
import { axiosService } from '../../axios';

interface Props extends BaseLoginProps {
  changeTileType: (type: TileType) => void
}

type SuccessStatusKeys = 'success'
type ErrorStatusKeys = 'token-expired'

export const ForgotPasswordTile = ({ changeTileType }: Props) => {
  const locationHash = window.location.hash
  const isTokenExpired = locationHash === '#token-expired'
  const isForgotPassword = locationHash === '#forgot-password'

  const responseSuccessStringMap: {[key in SuccessStatusKeys]: string | React.ReactNode} = {
    'success': 'Ok! We emailed you a link you can use to reset your password if we had that email on file.'
  }
  const responseErrorStringMap: {[key in ErrorStatusKeys]: string | React.ReactNode } = {
    'token-expired': 'The password reset request has expired. Please enter your email below to reset password'
  }

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<null | ErrorStatusKeys>(isTokenExpired ? 'token-expired' : null)
  const [result, setResult] = useState<null | SuccessStatusKeys>(null)
  const [formError, setFormError] = useState<null | string>(null);
  const [hasInlineError, setHasInlineError] = useState(false);

  const resetState = () => {
    if (isNil(error) && isNil(result) && !isLoading) return;
    setError(null);
    setResult(null);
    setIsLoading(false);
  }

  useEffect(() => {
    if (isForgotPassword) changeTileType('forgot-password')
  }, [isForgotPassword])

  const SetSuccessBannerText = (status: SuccessStatusKeys) => {
    return responseSuccessStringMap?.[status] ?? 'Success!';
  }

  const SetErrorBannerText = (errorType: ErrorStatusKeys) => {
    return responseErrorStringMap?.[errorType] ?? 'An unknown error occurred.';
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formValidation() || hasInlineError) return;
    if (isLoading) return;
    resetState();
    setIsLoading(true);
    try {
      const result = await axiosService.postRequest<SuccessStatusKeys, {user: { email: string }}>('/password_reset', { user: { email } })
      setResult(result.data.status);
    } catch (e) {
      setError(e?.error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleRedirect = () => {
    if (isTokenExpired) {
      window.location.href = '/login'
    } else {
      changeTileType('login')
    }
  }
  const formValidation = () => {
    if (!email) {
      setFormError('This field cannot be blank');
      return false;
    }
    return true;
  }
  return (
    <div>
      <FormRedirect>
        Already have an account? <a onClick={handleRedirect} className='text-brand-primary hover:underline hover:cursor-pointer'>Log In</a>
      </FormRedirect>
      <FormWrapper>
        <FormHeader>Forgot Password</FormHeader>
        {error &&
        <Banner
          className='mb-fsq-3'
          dismissible={false}
          bannerType='Error'
          bodyText={SetErrorBannerText(error)}
        />}
        {!isLoading && result &&
        <Banner
          className='mb-fsq-3'
          dismissible={false}
          bannerType='Success'
          bodyText={SetSuccessBannerText(result)}
        />}
        <p className='mb-6'>{`To reset your password, enter your email address
            and we'll send you instructions on how to create a new password`}
        </p>
        <form noValidate onSubmit={(e: React.FormEvent) => handleSubmit(e)}>
          <TextField
            type='email'
            label='Email'
            hideAsterisk={true}
            customError={formError ?? undefined}
            validate={(value) => {
              const result = validateEmailAddress(value as string)
              setHasInlineError(result ? true : false)
              return result;
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              resetState();
              setEmail(e.target.value)
              if (formError) setFormError(null);
            }}
            required
            className='mb-6'
          />
          <Button
            className={`w-36 ${isLoading ? 'cursor-wait' : ''}`}
            text='Submit'
            type='submit'
            buttonSize='lg'
            buttonStyle='fixed'
            icon='ArrowRight'
            disabled={isLoading}
          />
        </form>
      </FormWrapper>
    </div>
  )
}