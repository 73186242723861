import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const axiosClient = axios.create();

const postRequest = async <K, D>(url: string, data: D, config?: AxiosRequestConfig): Promise<AxiosResponse<{status: K}>> => {
  try {
    return await axiosClient.post(url, data, config);
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e?.response?.data;
    }
    throw { error: 'unknown-error' };
  }
}

const patchRequest = async <K, D>(url: string, data: D,  config?: AxiosRequestConfig): Promise<AxiosResponse<{status: K}>> => {
  try {
    return await axiosClient.patch(url, data, config);
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw e?.response?.data;
    }
    throw { error: 'unknown-error' };
  }
}
export const axiosService = {
  postRequest,
  patchRequest
};