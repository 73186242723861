import { Banner, Button, FormHeader, FormRedirect, FormWrapper, TextField } from '@foursquare/cupcake';
import React, { useState } from 'react';
import { TileType } from '../';
import { isNil } from 'lodash';
import { axiosService } from '../../axios';
import { validateEmailAddress } from '../../../utilities';

interface Props {
  changeTileType: (type: TileType) => void;
}

type SuccessStatusKeys = 'already-activated' | 'success';
type ErrorStatusKeys = 'unknown-error' | 'missing-email';

export const ActivationEmailTile = ({changeTileType}: Props) => {
  const responseSuccessStringMap: {[key in SuccessStatusKeys]: string | React.ReactNode} = {
    'already-activated': <div>This email has already been activated. You can <span className='underline cursor-pointer' onClick={() => changeTileType('login')}>log in</span> now using this email.</div>,
    'success': 'Ok! We just resent you the activation email.'
  }
  const responseErrorStringMap: {[key in ErrorStatusKeys]: string | React.ReactNode } = {
    'missing-email': 'The email you entered doesn\'t exist in our system. Please try again.',
    'unknown-error': 'An unknown error occurred. Please try again.'
  }
  const [email, setEmail] = useState('');
  const [error, setError] = useState<null | ErrorStatusKeys>(null);
  const [result, setResult] = useState<null | SuccessStatusKeys>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasInlineError, setHasInlineError] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);

  const resetState = () => {
    if (isNil(error) && isNil(result) && !isLoading) return;
    setError(null);
    setResult(null);
    setIsLoading(false);
  }

  const SetSuccessBannerText = (status: SuccessStatusKeys) => {
    return responseSuccessStringMap?.[status] ?? 'Success!';
  }

  const SetErrorBannerText = (errorType: ErrorStatusKeys) => {
    return responseErrorStringMap?.[errorType] ?? 'An unknown error occurred.';
  }

  const formValidation = () => {
    if (!email) {
      setFormError('This field cannot be blank');
      return false;
    }
    return true;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formValidation() || hasInlineError) return;
    if (isLoading) return;
    resetState();
    setIsLoading(true);
    try {
      const result = await axiosService.postRequest<SuccessStatusKeys, {email: string}>('users/resend_activation_email_v2', { email })
      setResult(result.data.status);
    } catch (e) {
      setError(e?.error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <FormRedirect>
        Already have an account? <a onClick={() => changeTileType('login')} className='text-brand-primary hover:underline hover:cursor-pointer'>Log In</a>
      </FormRedirect>
      <FormWrapper>
        <FormHeader>Resend Activation Email</FormHeader>
        <p className='mb-6'>{`Please enter the email address you registered your account with.
          You will receive an email from accounts@factual.com.
          Please make sure your spam protection is not blocking this address.`}
        </p>
        {!isLoading && !error && result &&
          <Banner
            className='mb-fsq-3'
            dismissible={false}
            bannerType={result === 'already-activated' ? 'Informational' : 'Success'}
            bodyText={SetSuccessBannerText(result)}
          />
        }
        {error && !isLoading &&
          <Banner
            className='mb-fsq-3'
            dismissible={false}
            bannerType='Error'
            bodyText={SetErrorBannerText(error)}
          />
        }
        <form noValidate onSubmit={(e) => handleSubmit(e)}>
          <TextField
            hideAsterisk={true}
            type='email'
            label='Email'
            customError={formError ?? undefined}
            validate={(value) => {
              const result = validateEmailAddress(value as string)
              setHasInlineError(result ? true : false);
              return result;
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              resetState();
              setEmail(e.target.value)
              if (formError) setFormError(null)
            }}
            required
            className='mb-6'
          />
          <Button
            className={`!w-36 ${isLoading ? 'cursor-wait' : ''}`}
            text='Submit'
            buttonSize='lg'
            buttonStyle='fixed'
            icon='ArrowRight'
            disabled={isLoading}
          />
        </form>
      </FormWrapper>
    </div>
  )
}