import { isEmpty } from 'lodash'

const EMAIL_REGEX_EXP = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const ACCOUNT_ID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
const MIN_PASSWORD_LENGTH = 6;

export const validateEmailAddress = (value: string) => {
  if (isEmpty(value)) {
    return 'Please enter an email address.';
  } else if (!value.match(EMAIL_REGEX_EXP)) {
    return 'Please enter a valid email';
  } else {
    return;
  }
};

export const validatePasswordLength = (value: string, minlength = MIN_PASSWORD_LENGTH) => {
  if (value.length < minlength) {
    return `Password must have at least ${minlength} characters.`;
  } else {
    return;
  }
};

export const validateSnapChatIdLength = (value: string) => {
  if (!value.match(ACCOUNT_ID_REGEX)) {
    return 'Please enter a valid account ID.';
  } else {
    return;
  }
};

export const validatePlatformLength = (value: string) => {
  if (value.length < 1) {
    return 'Please enter a valid advertiser ID.';
  } else {
    return ;
  }
};

export const getPlatformKeyName = (platform: string) => {
  if (platform === 'snapchat') {
    return 'account_ids';
  } else {
    return 'advertiser_ids';
  }
};
