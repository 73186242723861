import React, { useState } from "react"
import { Button } from "@foursquare/cupcake"
import { BaseLoginProps } from "../..";

export const OrgSelectForm = ({ authenticityToken, email, password, accounts}: BaseLoginProps) => {
  const [orgId, setOrgId] = useState('')

  return (
      <form method='post' noValidate>
        <input
          type="hidden"
          name="authenticity_token"
          value={authenticityToken}
        />
        <input
          type="hidden"
          name="user_session[email]"
          value={email as string}
        />
        <input
          type="hidden"
          name="user_session[password]"
          value={password as string}
        />
        <input
          type="hidden"
          name="user_session[org_id]"
          value={orgId}
        />
        {
          accounts.map((account, i) => {
            const text = account?.orgId ? (
              `${account.orgName} ${email}`
            ) : (
              `No associated organization - ${email}`
            )

            const className = (i == accounts.length - 1) ? '' : 'mb-6'
            return <div key={i} className={className}>
              <Button
                className="!w-full"
                type="submit"
                text={text}
                buttonSize="lg"
                buttonStyle='fixed'
                icon="ArrowRight"
                onClick={() => setOrgId(account?.orgId as string)}
              />
            </div>
          })
        }
      </form>
  )
}