import React from 'react';
import { LoginTile } from './LoginTile'
import { ForgotPasswordTile } from './ForgotPasswordTile';
import { BaseLoginProps, TileType } from '../index';
import { ActivationEmailTile } from './ActivationEmailTile';

export interface LoginWithTileProps extends BaseLoginProps {
  tileType: TileType
  changeTileType: (type: TileType) => void
}
export const Tile = ({tileType, ...props}: LoginWithTileProps) => {
  if (tileType === 'login') {
    return <LoginTile { ...props } />
  }

  if (tileType === 'forgot-password') {
    return <ForgotPasswordTile { ...props } />
  }

  if (tileType === 'resend-activation-email') {
    return <ActivationEmailTile {...props} />
  }
  // Should never hit this but is required for proper TypeScript
  return <div>Something went wrong.</div>
}