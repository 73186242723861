import React, { useEffect, useState } from 'react'
import { AuthLayout, Logo } from '@foursquare/cupcake'
import { Tile } from './components/Tile';
import { Footer } from './components/Footer'
import ReactOnRails from 'react-on-rails';

export type TileType = 'login' | 'forgot-password' | 'resend-activation-email';

export interface BaseLoginProps {
  dev: boolean;
  accounts: {orgId: string | null, orgName: string | null}[];
  email: string | null;
  password: string | null;
  loginPath: string;
  passwordResetPath: string;
  error: string | null;
  recaptchaKey: string;
  redirectTo: string | null;
  authenticityToken: string;
  resendActivationEmailPath: string;
}

const Login = (props: BaseLoginProps) => {
  const locationHash = window.location.hash
  const [tileType, setTileType] = useState<TileType>('login')

  useEffect(() => {
    if (locationHash === '#token-expired') {
      setTileType('forgot-password')
    }
  }, [locationHash])

  const changeTileType = (type: TileType) => {
    setTileType(type);
  }

  return (
    <AuthLayout
      className='min-w-[374px] max-w-[1440px]'
      formContent={<Tile {...{...props, tileType, changeTileType, authenticityToken: ReactOnRails.authenticityToken() as string}} />}
      footerContent={<Footer />}
      pageType="login"
      primaryHeaderText="Reach customers based on real-world movement"
      description="Leverage location data to better understand your customers and target them on their journey towards your brand."
    />
  )
}

export default Login
