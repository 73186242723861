import React from 'react';

const FooterLink = ({ href, children }) => {
  return (
    <a className='flex-initial hover:text-text-4' target='_blank' href={href}>
      {children}
    </a>
  );
};

export const Footer = () => {
  return (
    <div class='flex md:flex-row flex-col items-center gap-fsq-3'>
      <FooterLink href='https://location.foursquare.com/legal/privacy-center/'>
        Privacy Center
      </FooterLink>
      <FooterLink href='https://location.foursquare.com/legal/privacy-center/#media_and_text-v1-a322121e-92c0-497b-85ae-e6c4672842ed'>
        Your Privacy Choices
      </FooterLink>
      <FooterLink href='https://location.foursquare.com/legal/privacy-center/#media_and_text-v1-be6a9000-d234-4bde-982b-dbfd026deadf'>
        Cookie Policy
      </FooterLink>
      <FooterLink href='https://location.foursquare.com/legal/terms/'>
        Other Terms & Policies
      </FooterLink>
    </div>
  );
};
