import { Button, TextField, Link } from '@foursquare/cupcake';
import React, { useState } from 'react';
import { BaseLoginProps, TileType } from '../..';
import {
  validateEmailAddress,
  validatePasswordLength,
} from '../../../../utilities';
import { ReCaptcha } from '../LoginTile';

interface LoginForm extends BaseLoginProps {
  changeTileType: (tileType: TileType) => void;
}

const CANNOT_BE_BLANK = 'This field cannot be blank';

export const LoginForm = ({
  redirectTo,
  error,
  authenticityToken,
  changeTileType,
  recaptchaKey,
}: LoginForm) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hasInlineErrors, setHasInlineErrors] = useState({
    email: false,
    password: false,
  });
  const [loginFormErrors, setLoginFormErrors] = useState<{
    email: string | null;
    password: string | null;
  }>({
    email: null,
    password: null,
  });
  const loginFormValidation = () => {
    if (!email) {
      setLoginFormErrors((prevState) => ({
        ...prevState,
        email: CANNOT_BE_BLANK,
      }));
    }
    if (!password) {
      setLoginFormErrors((prevState) => ({
        ...prevState,
        password: CANNOT_BE_BLANK,
      }));
    }
    return password && email;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    if (
      !loginFormValidation() ||
      hasInlineErrors.email ||
      hasInlineErrors.password
    )
      e.preventDefault();
  };

  return (
    <form
      id='targeting-login-form'
      className='flex flex-col gap-fsq-4'
      method='post'
      onSubmit={(e) => handleSubmit(e)}
      noValidate
    >
      <input
        type='hidden'
        name='authenticity_token'
        value={authenticityToken}
      />
      <input
        type='hidden'
        name='user_session[redirect_to]'
        value={redirectTo ?? ''}
      />
      <TextField
        type='email'
        validate={(value) => {
          const result = validateEmailAddress(value as string);
          setHasInlineErrors((prevState) => ({
            ...prevState,
            email: result ? true : false,
          }));
          return result;
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (loginFormErrors.email) {
            setLoginFormErrors((prevState) => ({
              ...prevState,
              email: null,
            }));
          }
          setEmail(e.target.value);
        }}
        required
        customError={loginFormErrors.email as string}
        hideAsterisk
        name='user_session[email]'
        id='user_session[email]'
        label='Email Address'
      />
      <TextField
        type='password'
        validate={(value) => {
          const result = validatePasswordLength(value as string);
          setHasInlineErrors((prevState) => ({
            ...prevState,
            password: result ? true : false,
          }));
          return result;
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (loginFormErrors.password) {
            setLoginFormErrors((prevState) => ({
              ...prevState,
              password: null,
            }));
          }
          setPassword(e.target.value);
        }}
        required
        hideAsterisk
        customError={loginFormErrors.password ?? undefined}
        name='user_session[password]'
        id='user_session[password]'
        label='Password'
        labelExtra={
          <div
            onClick={() => changeTileType('forgot-password')}
            className='text-sm text-brand-primary hover:underline hover:cursor-pointer'
            // rel="noopener noreferrer"
          >
            Forgot password
          </div>
        }
      />
      <ReCaptcha recaptchaKey={recaptchaKey} error={error} />
      <div>
        <Button
          name='targeting-login-submit'
          id='targeting-login-submit'
          text='Log In'
          buttonType='primary'
          type='submit'
          icon='ArrowRight'
        />
      </div>
      <div
        className='text-sm text-brand-primary hover:underline hover:cursor-pointer'
        onClick={() => changeTileType('resend-activation-email')}
      >
        Haven’t received activation email?
      </div>
      <div className='text-sm'>
        Use of Audience Designer & Proximity Designer is subject to Foursquare’s{' '}
        <Link
          href='https://location.foursquare.com/legal/privacy-center/enterprise/'
          target='_blank'
        >
          Developer & Enterprise Privacy Policy
        </Link>{' '}
        and{' '}
        <Link
          href='https://location.foursquare.com/legal/terms/enterprise-master-terms/'
          target='_blank'
        >
          Enterprise Customer Master Terms
        </Link>
      </div>
    </form>
  );
};
